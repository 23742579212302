import gql from 'graphql-tag';

export const CART_FRAGMENT = gql`
  fragment CartFields on Cart {
    total_quantity
    prices {
      grand_total {
        value
      }
      subtotal_including_tax {
        value
      }
      subtotal_original_including_tax {
        value
       }
      applied_taxes {
        amount {
          value
        }
      }
    }
    email
    items {
      id
      uid
      quantity
      product {
        name
        sku
        canonical_url
        gift_wrapping_available
        ... on SimpleProduct {
          weight
        }
        price {
          regularPrice {
            amount {
              currency
              value
            }
          }
        }
        prices {
          amount
          unit
          old_amount
          currency_symbol
          percentage
        }
        thumbnail {
          label
          url
        }
        gallery {
          label
          position
          url
        }
        
      }
    }
    shipping_addresses {
      city
      firstname
      lastname
      postcode
      street
      telephone
      pickup_location_code
      country {
        code
      }
      selected_shipping_method {
        amount {
          currency
          value
        }
        carrier_code
        carrier_title
        method_code
        method_title
      }
    }
    billing_address {
      customer_address_id
      city
      company
      vat_id
      country {
        code
        label
      }
      firstname
      lastname
      postcode
      street
      telephone
    }
  }
`;

export const CREATE_EMPTY_CART = gql`
  mutation {
    createEmptyCart
  }
`;

export const ADD_PRODUCT_TO_CART = gql`
  ${CART_FRAGMENT}
  mutation ($sku: String!, $quantity: Float!) {
    addSimpleProductsToCart(input: { cart_items: [{ data: { sku: $sku, quantity: $quantity } }] }) {
      cart {
        ...CartFields
      }
    }
  }
`;

export const GET_CART = gql`
  ${CART_FRAGMENT}
  query {
    cart {
      email
      billing_address {
        city
        company
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        street
        telephone
      }
      selected_payment_method {
        code
        purchase_order_number
        title
      }
      applied_coupons {
        code
      }
      ...CartFields
    }
  }
`;

export const UPDATE_CART = gql`
  ${CART_FRAGMENT}
  mutation ($cartItems: [CartItemUpdateInput]!) {
    updateCartItems(input: { cart_items: $cartItems }) {
      cart {
        ...CartFields
      }
    }
  }
`;
